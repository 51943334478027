@import '~antd/dist/antd.dark.less';
@import '~antd/dist/antd.compact.less';

@font-size-base: 16px;
@secondary-color: 'red';
@primary-color: '#fff';

.secondary-color {
  color: @secondary-color
}

.border-primary-color {
  border-color: @primary-color !important;
}

.background-primary-color {
   background-color: @primary-color !important;
   color: #000;
}

.ant-card {
  background: @table-row-hover-bg;
  border-radius: 10px;
}

.main-cols {
  height: 97vh;
  padding: 5px;
}

.card-item {
  width: 100%;
  cursor: pointer;
}

.card-item:hover {
  opacity: 0.9;
}

//Override Menu

@menu-dark-bg: @background-color-base;
@menu-dark-item-active-bg: @table-row-hover-bg;
@menu-dark-item-hover-bg: @table-row-hover-bg;


//Override Input

@input-bg: @table-row-hover-bg;
@input-icon-hover-color: fade(@black, 20%);
@input-hover-border-color: @border-color-base;


// Override Button LG

.ant-btn-lg {
  width: 100%;
  border-radius: 20px;
}

// Override button default

@btn-default-bg: #fff;

.ant-btn-default {
  color: #000000;
}

.button-quantity {
  width: 120px;
  height: 120px;
}

@primary-color: #00b970;@link-color: #00b970;