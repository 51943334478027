@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    overscroll-behavior-y: contain;
}

.ant-btn {
    border-radius: 23px;
}

/* Cart item*/
.wrapper-cart-quantity{
    width: 9%;
}
.wrapper-cart-product-name{
    width: 79%;
}
.wrapper-cart-product-price{
    width: 12%;
}

/* Col Right - Order */
.wrapper-order-header{
    height: 10%;
}
.wrapper-order-items{
    height: 55%;
}
.wrapper-order-footer{
    height: 35%;
}

/*screen beewt 576 and 900*/


@media (min-width: 36em) and (max-width: 56.25em) {

    .wrapper-categories{
        height: 25%;
    }
    .wrapper-divider{
        height: 2%;
    }
    .wrapper-products{
        height: 73%;
    }

    /* Cart item*/
    .wrapper-cart-quantity{
        width: 9%;
    }
    .wrapper-cart-product-name{
        width: 79%;
    }
    .wrapper-cart-product-price{
        width: 12%;
    }

    /* Col Right - Order */

    .wrapper-order-header{
        height: 10%;
    }
    .wrapper-order-items{
        height: 55%;
    }
    .wrapper-order-footer{
        height: 35%;
    }

}

/*screen between 900 and 1200*/

@media (min-width: 56.25em) and (max-width: 75em) {
    .wrapper-categories{
        height: 15%;
    }
    .wrapper-divider{
        height: 2%;
    }
    .wrapper-products{
        height: 83%;
    }

    /* Cart item*/
    .wrapper-cart-quantity{
        width: 10%;
    }
    .wrapper-cart-product-name{
        width: 70%;
    }
    .wrapper-cart-product-price{
        width: 20%;
    }
}

/*screen between 1200 and 1600*/

@media (min-width: 75em) and (max-width: 100em) {
    .wrapper-categories{
        height: 8%;
    }
    .wrapper-divider{
        height: 2%;
    }
    .wrapper-products{
        height: 90%;
    }
}